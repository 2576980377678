// утилита для того, что бы наши аватарки понимали анонимный пользователь перед ней или нет
// TODO "научить" компонент аватарки различать тип дынных, приходящий для пропса name

// eslint-disable-next-line import/prefer-default-export
export const validateUserFullName = (lastName, firstName) => {
  if (lastName && firstName) {
    return `${lastName} ${firstName}`;
  }
  return '';
};
