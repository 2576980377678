<template>
  <div>
    <div
      v-if="showPrefix"
      :class="[disabled? 'ttw-light-mono-24--text':'tt-light-mono-46--text']"
      class="d-inline-flex align-center"
    >
      <span>
        {{ prefix }}
      </span>
      <slot
        name="divider"
      >
        <div
          class="bullet mx-1"
        />
      </slot>
    </div>
    <span>
      <slot>{{ text }}</slot>
    </span>
  </div>
</template>
<script>
export default {
  name: 'TextTemplate',
  props: {
    prefix: {
      type: String,
      default: 'Тестовая',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    showPrefix: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
