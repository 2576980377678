<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <div class="delete-form__title">
          Удалить пункт отчета?
        </div>
        <p>
          Пункт будет удален без возможности восстановления
          и будет недоступен для выбора при создании шаблонов встреч.
        </p>
      </DeleteForm>
    </DialogWrapper>
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol>
                <h1
                  class="mb-3 tt-text-headline-1"
                  data-test="title-form"
                >
                  {{ edit ? 'Редактирование' : 'Создание' }} шаблона встречи
                </h1>
                <p
                  v-if="globalErrorMessage"
                  class="error--text mt-2"
                >
                  {{ globalErrorMessage }}
                </p>
              </VCol>
            </VRow>
            <VForm
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <VRow>
                <VCol
                  cols="12"
                >
                  <TTAutocomplete
                    v-model="staffId"
                    label="Участники встречи"
                    no-rotate
                    large
                    attach
                    :loading="loading"
                    :items="staff"
                    :search-input.sync="search"
                    item-value="id"
                    item-text="name"
                    return-object
                    data-test-label="autocomplete-meeting-participants"
                    append-icon="fal fa-search"
                    placeholder="Поиск по имени и фамилии сотрудника"
                    hide-details="auto"
                    :error="validation('content_id').status"
                    :error-messages="validation('content_id').value"
                    @change="v => handleStaffChange(v)"
                  />
                </VCol>
                <VCol
                  v-if="partStaff.length > 0"
                  cols="12"
                >
                  <VRow
                    v-for="staffItem in partStaff"
                    :key="staffItem.id"
                    align="center"
                  >
                    <VCol class="flex-grow-0">
                      <NewSAvatar
                        data-test="checkpoint-template-staff-photo"
                        :img="staffItem.photo_url"
                        :name="`${staffItem.name}`"
                      />
                    </VCol>
                    <VCol cols="4">
                      <div class="text-black">
                        {{ staffItem.name }}
                      </div>
                    </VCol>
                    <VCol>
                      <VCheckbox
                        v-model="staffItem.report"
                        hide-details
                        single-line
                        class="mt-0"
                        color="tt-black"
                        label="Отправить отчет по итогам встречи"
                        data-test="checkpoint-template-staff-report-checkbox"
                        selected-color="red"
                        :ripple="false"
                      />
                    </VCol>
                    <VCol
                      cols="3"
                      class="text-right"
                    >
                      <TTBtn
                        fab
                        depressed
                        color="white"
                        small
                        :data-test="`button-remove-${staffItem.id}`"
                        @click.stop="removePart(roles.STAFF, staffItem.id)"
                      >
                        <VIcon>
                          fal fa-times
                        </VIcon>
                      </TTBtn>
                    </VCol>
                    <VCol
                      cols="12"
                      class="py-2"
                    >
                      <VDivider />
                    </VCol>
                  </VRow>
                </VCol>
                <VCol
                  cols="12"
                >
                  <!--  HR  -->
                  <VRow
                    align="center"
                    class="flex-nowrap"
                  >
                    <VCol class="flex-grow-0">
                      <NewSAvatar
                        data-test="checkpoint-template-hr-photo"
                        :name="validateUserFullName()"
                      />
                    </VCol>
                    <VCol cols="4">
                      <div :class="partHr ? 'text-black': 'tt-black--text text--lighten-2'">
                        HR
                      </div>
                    </VCol>
                    <VCol>
                      <VCheckbox
                        v-if="partHr"
                        v-model="partHr.report"
                        :ripple="false"
                        hide-details
                        single-line
                        color="tt-black"
                        class="mt-0"
                        label="Отправить отчет по итогам встречи"
                        data-test="checkpoint-template-hr-report-checkbox"
                      />
                    </VCol>
                    <VCol
                      v-if="partHr"
                      cols="3"
                      class="text-right"
                    >
                      <TTBtn
                        fab
                        depressed
                        color="white"
                        small
                        data-test="button-remove-hr"
                        @click.stop="removePart(roles.HR)"
                      >
                        <VIcon>
                          fal fa-times
                        </VIcon>
                      </TTBtn>
                    </VCol>
                    <template v-else>
                      <VCol
                        cols="2"
                        class="text-right"
                      >
                        <span class="tt-black--text text--lighten-2">Добавить участника</span>
                      </VCol>
                      <VCol class="flex-grow-0">
                        <TTBtn
                          fab
                          depressed
                          small
                          color="white"
                          data-test="button-add-hr"
                          @click.stop="addPart({
                            role : roles.HR,
                            report : true
                          })"
                        >
                          <VIcon>
                            fal fa-plus
                          </VIcon>
                        </TTBtn>
                      </VCol>
                    </template>
                  </VRow>
                  <VRow>
                    <VCol
                      cols="12"
                      class="py-2"
                    >
                      <VDivider />
                    </VCol>
                  </VRow>
                  <!--  MENTOR  -->
                  <VRow
                    align="center"
                    class="flex-nowrap"
                  >
                    <VCol class="flex-grow-0">
                      <NewSAvatar
                        :name="validateUserFullName()"
                        data-test="checkpoint-template-mentor-photo"
                      />
                    </VCol>
                    <VCol cols="4">
                      <div :class="partMentor ? 'text-black': 'tt-black--text text--lighten-2'">
                        Руководитель
                      </div>
                    </VCol>
                    <VCol>
                      <VCheckbox
                        v-if="partMentor"
                        v-model="partMentor.report"
                        hide-details
                        single-line
                        class="mt-0"
                        color="tt-black"
                        label="Отправить отчет по итогам встречи"
                        data-test="checkpoint-template-mentor-report-checkbox"
                        :ripple="false"
                      />
                    </VCol>
                    <VCol
                      v-if="partMentor"
                      cols="3"
                      class="text-right"
                    >
                      <TTBtn
                        fab
                        depressed
                        small
                        color="white"
                        data-test="button-remove-mentor"
                        @click.stop="removePart(roles.MENTOR)"
                      >
                        <VIcon>
                          fal fa-times
                        </VIcon>
                      </TTBtn>
                    </VCol>
                    <template v-else>
                      <VCol
                        cols="2"
                        class="text-right"
                      >
                        <span class="tt-black--text text--lighten-2">Добавить участника</span>
                      </VCol>
                      <VCol class="flex-grow-0">
                        <TTBtn
                          fab
                          depressed
                          x-small
                          color="white"
                          data-test="button-add-mentor"
                          @click.stop="addPart({
                            role : roles.MENTOR,
                            report : true
                          })"
                        >
                          <VIcon>
                            fal fa-plus
                          </VIcon>
                        </TTBtn>
                      </VCol>
                    </template>
                  </VRow>
                  <VRow>
                    <VCol
                      cols="12"
                      class="py-2"
                    >
                      <VDivider />
                    </VCol>
                  </VRow>
                  <!--  TUTOR  -->
                  <VRow
                    align="center"
                    class="flex-nowrap"
                  >
                    <VCol class="flex-grow-0">
                      <NewSAvatar
                        :name="validateUserFullName()"
                        data-test="checkpoint-template-tutor-photo"
                      />
                    </VCol>
                    <VCol cols="4">
                      <div :class="partTutor ? 'text-black': 'tt-black--text text--lighten-2'">
                        Наставник
                      </div>
                    </VCol>
                    <VCol>
                      <VCheckbox
                        v-if="partTutor"
                        v-model="partTutor.report"
                        hide-details
                        single-line
                        class="mt-0"
                        color="tt-black"
                        label="Отправить отчет по итогам встречи"
                        data-test="checkpoint-template-mentor-report-checkbox"
                        :ripple="false"
                      />
                    </VCol>
                    <VCol
                      v-if="partTutor"
                      cols="3"
                      class="text-right"
                    >
                      <TTBtn
                        fab
                        depressed
                        small
                        color="white"
                        data-test="button-remove-mentor"
                        @click.stop="removePart(roles.TUTOR)"
                      >
                        <VIcon>
                          fal fa-times
                        </VIcon>
                      </TTBtn>
                    </VCol>
                    <template v-else>
                      <VCol
                        cols="2"
                        class="text-right"
                      >
                        <span class="tt-black--text text--lighten-2">Добавить участника</span>
                      </VCol>
                      <VCol class="flex-grow-0">
                        <TTBtn
                          fab
                          depressed
                          small
                          color="white"
                          data-test="button-add-mentor"
                          @click.stop="addPart({
                            role : roles.TUTOR,
                            report : true
                          })"
                        >
                          <VIcon>
                            fal fa-plus
                          </VIcon>
                        </TTBtn>
                      </VCol>
                    </template>
                  </VRow>
                  <VRow>
                    <VCol
                      cols="12"
                      class="py-2"
                    >
                      <VDivider />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="6">
                  <TTSelect
                    :value="entity.interval"
                    :items="intervalList"
                    item-text="label"
                    large
                    data-test-label="select-meeting-participants"
                    label="Интервал"
                    placeholder="Выберите интервал"
                    :messages="['Выберите из предложенных интервалов или задайте свой']"
                    :error="validation('interval').status"
                    :error-messages="validation('interval').value"
                    @change="v => handleChange('interval',v)"
                  />
                </VCol>
                <VCol
                  v-if="entity.interval === -1"
                  cols="3"
                >
                  <TTTextField
                    v-model="interval"
                    v-mask="'###'"
                    large
                    label="День встречи"
                    placeholder="0"
                    data-test-label="date-meeting-field"
                    :error="validation('interval').status"
                    :error-messages="validation('interval').value"
                    @input="handleChange('',undefined, 'interval')"
                  />
                </VCol>
                <VCol
                  :cols="entity.interval === -1? 3 : 6"
                >
                  <CtxTimePicker
                    placeholder="--:--"
                    attach
                    append-icon="fal fa-clock"
                    :value="entity.meet_time"
                    label="Время"
                    single-line
                    data-test-label="field-time"
                    hide-details="auto"
                    :minute-interval="10"
                    :error="validation('meet_time').status"
                    :error-messages="validation('meet_time').value"
                    @input="v => handleChange('meet_time',v)"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="12">
                  <TTTextField
                    :value="entity.name"
                    large
                    label="Название"
                    placeholder="Название"
                    data-test-label="meeting-name-field"
                    :messages="['Укажите название встречи. Под этим названием он будет отображаться в общей схеме']"
                    :error="validation('name').status"
                    :error-messages="validation('name').value"
                    @input="v => handleChange('name',v)"
                  />
                </VCol>
                <VCol cols="12">
                  <TTTextarea
                    :maxlength="10000"
                    :value="entity.description"
                    label="Описание встречи"
                    placeholder="Укажите описание встречи"
                    height="92"
                    data-test-label="meeting-description-field"
                    single-line
                    hide-details="auto"
                    :error="validation('description').status"
                    :error-messages="validation('description').value"
                    @input="v => handleChange('description',v)"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  class="mb-3 mt-10"
                >
                  <VDivider />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="12">
                  <h2 class="tt-text-title-2 mb-2">
                    Отчет после встречи
                  </h2>
                  <p class="tt-black--text text--lighten-2 mt-2">
                    Выберите пункты, которые войдут в отчет по результатам этой встречи.
                  </p>
                </VCol>
              </VRow>
              <template v-for="(problemItem, i) in entity.problems">
                <VRow
                  v-if="!problemItem.delete"
                  :key="i"
                  class="my-0"
                  align="center"
                >
                  <VCol cols="11">
                    <VCheckbox
                      v-model="problemItem.use"
                      hide-details="auto"
                      single-line
                      color="tt-black"
                      class="mt-0"
                      data-test="checkbox-in-report-after-meeting"
                      :label="problemItem.name"
                      :ripple="false"
                    />
                  </VCol>
                  <VCol cols="1">
                    <TTBtn
                      fab
                      depressed
                      color="white"
                      small
                      data-test="button-remove-mentor"
                      @click.stop="removeProblem(i)"
                    >
                      <VIcon>
                        fal fa-trash-alt
                      </VIcon>
                    </TTBtn>
                  </VCol>
                  <VCol
                    cols="12"
                    class="py-1"
                  >
                    <VDivider />
                  </VCol>
                </VRow>
              </template>
              <VRow align="center">
                <VCol cols="10">
                  <TTTextField
                    v-model="problem"
                    large
                    label="Новый пункт"
                    data-test-label="new-item-text"
                    placeholder="Введите новый пункт"
                    :messages="['Добавьте пункт, которого нет в списке']"
                  />
                </VCol>
                <VCol cols="2">
                  <TTBtn
                    class="mr-3 mt-2"
                    :disabled="!problem"
                    data-test="button-add-problem"
                    color="primary"
                    large
                    block
                    @click="addProblem"
                  >
                    Добавить
                  </TTBtn>
                </VCol>
              </VRow>
              <VRow>
                <VCol
                  cols="12"
                  class="mb-4"
                >
                  <VDivider />
                </VCol>
                <VCol>
                  <TTBtn
                    large
                    color="tt-secondary"
                    depressed
                    class="mr-6"
                    data-test="button-cancel"
                    @click="handleClose"
                  >
                    Отменить
                  </TTBtn>
                  <TTBtn
                    large
                    depressed
                    data-test="button-save-pattern"
                    :disabled="saving"
                    :loading="saving"
                    @click="save(false)"
                  >
                    {{ edit ? 'Сохранить' : 'Создать' }} шаблон
                  </TTBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        style="text-align: center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              large
              color="white"
              elevation="1"
              class="mb-2"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validateUserFullName } from '@/utils/validateUserFullName';
import { mask } from 'vue-the-mask';
import cloneDeep from 'lodash/cloneDeep';
import { validation, handleError, watchValidationStatus } from '@/services';
import * as snamiApi from '@/services/backend/snami';
import { roles, dictionaryKeys } from '@/constants';

import NewSAvatar from '@/components/ui/NewSAvatar.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import CtxTimePicker from '@/components/ui/CtxTimePicker.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

const defaultEntity = {
  description: '',
  interval: '',
  location_id: null,
  meet_time: '',
  name: '',
  participants: [],
  problems: [],
};

export default {
  name: 'CheckpointTemplateForm',
  directives: { mask },
  components: {
    DialogWrapper,
    CtxTimePicker,
    DeleteForm,
    NewSAvatar,
  },
  props: {
    editEntity: {
      type: Object,
      default: () => ({}),
    },
    problems: {
      type: Array,
      default: () => ([]),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
    staffMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: true,
      validationStatus: [],
      globalErrorMessage: '',
      loading: false,
      saving: false,
      search: null,
      interval: '',
      problem: '',
      roles,
      staff: [],
      staffId: 0,
      toDeleteId: 0,
      deleteDialog: false,
      partStaff: [],
      intervalList: [],
      partHr: null,
      partMentor: null,
      partTutor: null,
      isLoading: false,
      entity: {},
    };
  },
  computed: {
    staffIds() {
      return this.partStaff.map((item) => item.id);
    },
  },
  watch: {
    search(val) {
      this.handleSearch(val);
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  async created() {
    this.entity = cloneDeep(defaultEntity);
    this.handleSearch();
    try {
      const { data: { data: intervalList } } = await this.$repositories.customer
        .getCustomerDictionary({
          data: {
            key: [dictionaryKeys.checkpointTemplate],
          },
        });
      this.intervalList = intervalList.checkpoint_template_interval;
      this.intervalList.push({
        value: -1,
        label: 'Пользовательский интервал',
      });
      if (this.edit) {
        this.handleEntity();
      } else {
        this.partHr = {
          report: true,
          role: roles.HR,
        };
        this.partMentor = {
          report: true,
          role: roles.MENTOR,
        };
        this.partTutor = {
          report: true,
          role: roles.TUTOR,
        };
        this.entity.problems = this.problems.map((item) => ({
          id: item.id,
          name: item.name,
          use: true,
        }));
        this.entity.location_id = this.locationId;
      }
    } catch (e) {
      console.warn(e);
    }
  },
  methods: {
    validation,
    handleError,
    validateUserFullName,
    save() {
      this.saving = true;
      const data = { ...this.entity };
      data.participants = this.partStaff.map((item) => ({
        id: item.id,
        role: item.role,
        report: item.report,
      }));
      if (this.partHr) {
        data.participants.push(this.partHr);
      }
      if (this.partMentor) {
        data.participants.push(this.partMentor);
      }
      if (this.partTutor) {
        data.participants.push(this.partTutor);
      }
      if (data.interval === -1) {
        data.interval = `${this.interval || 0}d`;
      }
      snamiApi.saveCheckpointTemplate({
        type: this.edit ? 'update' : 'create',
        data,
      })
        .then(() => {
          this.$emit('save', data);
        })
        .catch((e) => this.handleError(e))
        .finally(() => {
          this.saving = false;
        });
    },
    addProblem() {
      this.entity.problems.push({
        name: this.problem,
        use: true,
      });
      this.problem = '';
    },
    removeProblem(index) {
      if (this.entity.problems[index].id) {
        this.openDelete(this.entity.problems[index]);
      } else {
        this.entity.problems.splice(index, 1);
      }
    },
    removePart(role, id) {
      if (role === roles.STAFF) {
        const itemIndex = this.partStaff.findIndex((item) => item.id === id);
        this.partStaff.splice(itemIndex, 1);
      } else if (role === roles.HR) {
        this.partHr = null;
      } else if (role === roles.MENTOR) {
        this.partMentor = null;
      } else if (role === roles.TUTOR) {
        this.partTutor = null;
      }
    },
    addPart(participant) {
      if (participant.role === roles.STAFF) {
        this.partStaff.push(participant);
      } else if (participant.role === roles.HR) {
        this.partHr = participant;
      } else if (participant.role === roles.MENTOR) {
        this.partMentor = participant;
      } else if (participant.role === roles.TUTOR) {
        this.partTutor = participant;
      }
    },
    handleDelete() {
      const problemIndex = this.entity.problems.findIndex((item) => item.id === this.toDeleteId);
      const problem = {
        ...this.entity.problems[problemIndex],
        delete: true,
      };
      this.entity.problems.splice(problemIndex, 1, problem);
      this.deleteDialog = false;
      this.toDeleteId = 0;
    },
    openDelete(item) {
      this.deleteDialog = true;
      this.toDeleteId = item.id;
    },
    handleSearch(text) {
      this.loading = true;
      this.$repositories.staff.list({
        data: {
          filter: {
            location_id: null,
            is_fired: false,
            search_text: text,
          },
          page_size: 20,
        },
      })
        .then((r) => {
          const { data } = r.data;
          if (data) {
            this.staff = data
              .filter((item) => this.staffIds.indexOf(item.id) === -1)
              .map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
                photo_url: item.photo_url,
              }));
          } else {
            this.staff = [];
          }
        })
        .catch((e) => console.warn(e))
        .finally(() => {
          this.loading = false;
        });
    },
    handleEntity() {
      const {
        participants,
        problems,
        ...rest
      } = this.editEntity;
      this.entity = Object.assign(this.entity, rest);
      this.entity.problems = problems.map((item) => ({
        id: item.id,
        name: item.name,
        use: item.is_used !== undefined ? item.is_used : true,
      }));
      participants.forEach((item) => {
        let staff = null;
        // eslint-disable-next-line default-case
        switch (item.role) {
          case roles.STAFF:
            staff = this.staffMap[item.id];
            if (staff) {
              this.partStaff.push({
                ...staff,
                report: item.report,
                role: roles.STAFF,
              });
            }
            break;
          case roles.HR:
            this.partHr = { ...item };
            break;
          case roles.MENTOR:
            this.partMentor = { ...item };
            break;
          case roles.TUTOR:
            this.partTutor = { ...item };
            break;
        }
      });
      if (this.intervalList.findIndex((item) => item.value === this.entity.interval) === -1) {
        this.interval = this.entity.interval;
        this.entity.interval = -1;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    handleStaffChange(value) {
      if (value) {
        this.addPart({
          id: value.id,
          photo_url: value.photo_url,
          report: true,
          name: value.name,
          role: roles.STAFF,
        });
      }
      this.validationStatus = this.validationStatus.filter((i) => i.key !== 'participants');

      this.$nextTick(() => {
        this.search = '';
        this.staffId = 0;
      });
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
  },
};
</script>

<style scoped>
/* ToDo при обновлении до 3-ей вьютифая версии поправить чек-боксы:
    добавить им пропсы selected-color, эти стили удалить */

.v-application .primary--text {
  color: #000 !important;
  caret-color: #000 !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

::v-deep .v-label {
  font-size: 14px;
}
</style>
