<template>
  <TTTextField
    v-if="readonly || disabled"
    large
    :value="value"
    readonly
    v-bind="customAttrs"
    :class="contentClass"
    :disabled="disabled"
  />
  <VMenu
    v-else
    v-model="menu"
    content-class="v-menu-shadow"
    :attach="attach"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="200px"
    @input="menu = false"
  >
    <template #activator="{ on }">
      <TTTextField
        ref="textInput"
        v-mask="mask"
        large
        :value="textTime"
        :error-messages="errorMessagesAttr"
        v-bind="customAttrs"
        :class="contentClass"
        @click="menu = true"
        @click:append="menu = true"
        v-on="on"
        @input="v => handleTextChange(v)"
        @keydown.tab="menu = false"
        @change="handleChange"
      />
    </template>
    <VCard>
      <CtkDateTimePicker
        ref="timePicker"
        v-model="time"
        only-time
        overlay
        inline
        :minute-interval="minuteInterval"
        :disabled-hours="disabledHours"
        :format="format"
        :formatted="format"
        :output-format="format"
        no-header
        no-button
        v-on="listeners"
        @input="v => save(v)"
      />
    </VCard>
  </VMenu>
</template>
<script>
import { mask } from 'vue-the-mask';

const timeRegex = /[HhMm]/g;

export default {
  name: 'CtxTimePicker',
  directives: { mask },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
    minuteInterval: {
      type: Number,
      default: 5,
    },
    disabledHours: {
      type: Array,
      default: () => ([]),
    },
    format: {
      type: String,
      default: 'HH:mm',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    contentClass: {
      type: [String, Object, Array],
      default: '',
    },
    attach: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorMessage: '',
    textTime: '',
    textTimeDirty: false,
    menu: false,
    time: null,
  }),
  computed: {
    customAttrs() {
      const {
        readonly, disabled, format, maxDate, minDate, errorMessages, attach, ...others
      } = this.$attrs;
      return { ...others };
    },
    listeners() {
      const { input, change, ...others } = this.$listeners;
      return { ...others };
    },
    errorMessagesAttr() {
      if (!this.errorMessage) return this.errorMessages;
      if (Array.isArray(this.errorMessages)) {
        return this.errorMessages[0] ? this.errorMessages.concat(this.errorMessage) : [this.errorMessage];
      }
      return this.errorMessages ? [this.errorMessages, this.errorMessage] : [this.errorMessage];
    },
    mask() {
      return this.format.replace(timeRegex, '#');
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        // bug in inline vue-ctk-date-time-picker https://github.com/chronotruck/vue-ctk-date-time-picker/issues/126
        const el = this.$refs.timePicker.$children[0].$children[0];
        window.removeEventListener('keydown', el.keyPressed);
      } else {
        // Ждем пока закончится транзишн открытия окна, и только после выставляем значение.
        // Иначе не показывает установленное значение
        setTimeout(() => {
          this.time = this.value;
        }, 500);
      }
      // Зачем ставить в фокус? Убрано потому что вызывает ошибки
      // this.$refs.textInput.focus();
    },
    value: {
      handler(val) {
        this.textTime = val;
      },
      immediate: true,
    },
  },
  methods: {
    validate(val) {
      this.errorMessage = '';
      this.textTimeDirty = false;
      const formatString = this.format.replace(timeRegex, '\\d');
      const formatRegex = new RegExp(formatString);
      if (!val.match(formatRegex)) {
        this.errorMessage = 'Неверный формат';
        return false;
      }
      const splitVal = val.split(this.format.replace(timeRegex, ''));
      if (parseInt(splitVal[0], 10) > 23) {
        this.errorMessage = 'Неверный формат';
        return false;
      }
      if (parseInt(splitVal[1], 10) > 59) {
        this.errorMessage = 'Неверный формат';
        return false;
      }
      return true;
    },
    handleTextChange(v) {
      this.errorMessage = '';
      this.textTimeDirty = true;
      this.textTime = v;
      this.menu = true;
    },
    handleChange() {
      if (!this.textTimeDirty) return;
      if (!this.textTime) {
        this.save('');
      } else if (this.validate(this.textTime)) {
        this.save(this.textTime);
      }
    },
    save(v) {
      this.time = v;
      this.errorMessage = '';
      this.$emit('input', v);
      // Зачем ставить в фокус? Убрано потому что вызывает ошибки
      // this.$refs.textInput.focus();
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
