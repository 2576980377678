<template>
  <VContainer
    fluid
    class="checkpoint-template"
  >
    <DialogWrapper
      v-model="dialog"
      fullscreen
      @click:outside="closeModal('dialog')"
    >
      <VCardText>
        <CheckpointTemplateForm
          v-if="dialog"
          :edit="edit"
          :edit-entity="activeEntity"
          :problems="problems"
          :staff-map="staffMap"
          :location-id="currentLocationId"
          @save="handleSave"
          @close="closeModal('dialog')"
        />
      </VCardText>
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        v-if="toDeleteId"
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить шаблон встречи?
        </h3>
        <p class="mb-3">
          Шаблон встречи «{{ selectedTemplateForDelete.name }}» будет удален,
          при этом все ранее назначенные сотрудникам встречи по этому шаблону будут сохранены.
        </p>
        <p class="mb-0">
          Для новых сотрудников эта встреча назначаться не будет.
        </p>
      </DeleteForm>
    </DialogWrapper>
    <VRow>
      <VCol cols="12">
        <VRow class="align-center">
          <VCol cols="12">
            <h1 class="tt-text-headline-1">
              Схема шаблонов встреч
            </h1>
            <div class="tt-black--text text--lighten-2 mb-3">
              <template v-if="needMeetingTags">
                Здесь вы можете отредактировать или удалить шаблоны встреч.
              </template>
              <template v-else>
                Вносите изменения в шаблоны чекпоинтов.
              </template>
            </div>
          </VCol>
        </VRow>
        <VRow
          v-if="!needMeetingTags"
          class="mb-4 align-end"
        >
          <VCol
            cols="9"
            class="py-0"
          >
            <TTSelect
              :value="currentLocationId"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
              large
              label="Локация"
              :menu-props="{ auto : true }"
              class="tt-select-attach"
              attach
              @change="handleLocation"
            >
              <template #selection="{item,disabled}">
                <TextTemplate
                  class="v-select__selection v-select__selection--comma"
                  :disabled="disabled"
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
              <template #item="{item}">
                <TextTemplate
                  :text="item.name"
                  :show-prefix="!item.is_active && !!item.id"
                />
              </template>
            </TTSelect>
          </VCol>
          <VCol
            cols="3"
            class="py-0 flex-grow-0"
          >
            <TTBtn
              large
              block
              data-test="add-checkpoint"
              :disabled="!currentLocationId"
              @click="dialog = true"
            >
              Добавить шаблон
            </TTBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <TTDataTable
              :items="templates"
              :headers="headers"
              :items-per-page="-1"
              hide-default-footer
              data-test="table-location"
            >
              <template #item.name="{item}">
                <div class="d-flex align-center">
                  <div
                    data-test="table-template-name"
                    class="checkpoint-template__name ellipsis-two-lines"
                  >
                    <TTTooltip
                      top
                      max-width="640"
                      :disabled="item.name.length < 100"
                    >
                      <template #activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.name }}
                        </span>
                      </template>
                      <p
                        style="max-width: 640px"
                        class="mb-0"
                      >
                        {{ item.name }}
                      </p>
                    </TTTooltip>
                  </div>
                </div>
              </template>
              <template #item.interval="{item}">
                <div data-test="table-template-interval">
                  {{ intervalToString(item.interval) }}
                  <span v-if="item.meet_time">, {{ item.meet_time }}</span>
                </div>
              </template>
              <template #item.participants="{item}">
                <div class="d-flex align-center justify-space-between">
                  <div data-test="table-template-participants">
                    <template v-if="staffList(item.participants).length > 0">
                      <div
                        v-for="staff in staffList(item.participants)"
                        :key="staff.id"
                        class="mb-1"
                      >
                        <div>
                          {{ staffMap[staff.id] && staffMap[staff.id].name }}
                        </div>
                        <div
                          v-if="staffMap[staff.id] && staffMap[staff.id].position"
                          class="tt-black--text text--lighten-2"
                        >
                          {{ staffMap[staff.id].position }}
                        </div>
                      </div>
                    </template>

                    <div
                      v-if="hasHR(item.participants)"
                      class="mb-1"
                    >
                      HR
                    </div>
                    <div
                      v-if="hasMentor(item.participants)"
                      class="mb-1"
                    >
                      Руководитель
                    </div>
                    <div
                      v-if="hasTutor(item.participants)"
                      class="mb-1"
                    >
                      Наставник
                    </div>
                  </div>
                  <div>
                    <TTBtn
                      fab
                      depressed
                      small
                      color="transparent tt-ghost--text"
                      :ripple="false"
                      :data-test="`button-edit-${item.id}`"
                      @click="openModal('dialog',item)"
                    >
                      <VIcon size="19">
                        fal fa-pencil
                      </VIcon>
                    </TTBtn>
                    <TTBtn
                      fab
                      depressed
                      color="transparent tt-ghost--text"
                      :ripple="false"
                      small
                      :data-test="`button-delete-${item.id}`"
                      @click.stop="openDelete(item)"
                    >
                      <VIcon size="19">
                        fal fa-trash-alt
                      </VIcon>
                    </TTBtn>
                  </div>
                </div>
              </template>
            </TTDataTable>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import get from 'lodash/get';
import { pageLocationToken } from '@/services';
import * as snamiApi from '@/services/backend/snami';
import { roles, dictionaryKeys } from '@/constants';
import CheckpointTemplateForm from '@/components/forms/CheckpointTemplateForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'TemplateList',
  components: {
    TextTemplate, DialogWrapper, DeleteForm, CheckpointTemplateForm,
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      loading: false,
      intervalMap: {},
      locations: [],
      templates: [],
      staffMap: {},
      currentLocationId: null,
      toDeleteId: 0,
      edit: false,
      activeEntity: {},
      headers: [
        {
          text: 'Название встречи',
          value: 'name',
          sortable: false,
          width: '45%',
        },
        {
          text: 'Время проведения',
          value: 'interval',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Участники',
          value: 'participants',
          sortable: false,
          width: '35%',
        },
      ],
    };
  },
  computed: {
    problems() {
      return get(this.templates, '0.problems', []);
    },
    selectedTemplateForDelete() {
      return this.templates.find((tem) => tem.id === this.toDeleteId);
    },
    needMeetingTags() {
      return this.$di.ff.get('meeting_tags');
    },
  },
  async created() {
    const loc = pageLocationToken.get();
    try {
      const [
        { data: { data: intervalList } },
        { data: { data: locations } },
      ] = await Promise.all([
        this.$repositories.customer.getCustomerDictionary({
          data: {
            key: [dictionaryKeys.checkpointTemplate],
          },
        }),
        this.$repositories.location.list({ filter: {} }),
      ]);
      this.locations = locations;
      intervalList.checkpoint_template_interval.forEach((item) => {
        this.intervalMap[item.value] = item.label;
      });
    } catch (e) {
      console.warn(e);
    }
    if (loc) {
      this.currentLocationId = parseInt(loc, 10);
      this.getData();
    }
  },
  mounted() {
    this.$root.$on('showMeetTemplateForm', this.showAdd);
  },
  beforeDestroy() {
    this.$root.$off('showMeetTemplateForm', this.showAdd);
  },
  methods: {
    intervalToString(interval) {
      if (!interval) return this.intervalMap['1d'];
      if (this.intervalMap[interval]) return this.intervalMap[interval];
      const days = parseInt(interval.split('d')[0], 10);
      return `${days}-й день`;
    },
    hasHR(arr) {
      return Boolean(arr.find((item) => item.role === roles.HR));
    },
    hasMentor(arr) {
      return Boolean(arr.find((item) => item.role === roles.MENTOR));
    },
    hasTutor(arr) {
      return Boolean(arr.find((item) => item.role === roles.TUTOR));
    },
    staffList(arr) {
      return arr.filter((item) => item.role === roles.STAFF);
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.getData();
    },
    handleDelete() {
      snamiApi.deleteCheckpointTemplate(this.toDeleteId).then(() => {
        this.deleteDialog = false;
        this.toDeleteId = 0;
        this.getData();
      }).catch((e) => console.warn(e));
    },
    openDelete(item) {
      this.deleteDialog = true;
      this.toDeleteId = item.id;
    },
    handleSave() {
      this.closeModal('dialog');
      this.getData();
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    closeModal(name) {
      this[name] = false;
      this.edit = false;
      this.activeEntity = {};
    },
    getData() {
      this.loading = true;
      if (!this.currentLocationId) return;
      snamiApi.getCheckpointTemplateList({ filter: { location_id: this.currentLocationId } })
        .then((r) => {
          const { data } = r.data;
          this.templates = data;
          const staffIds = [];
          this.templates.forEach((template) => {
            template.participants.forEach((item) => {
              if (item.role === roles.STAFF && staffIds.indexOf(item.id) === -1) {
                staffIds.push(item.id);
              }
            });
          });
          if (staffIds.length > 0) {
            return this.$repositories.staff
              .list({ data: { filter: { id: staffIds }, page_size: staffIds.length } });
          }
          return null;
        }).then((r) => {
          this.staffMap = {};
          if (!r) return;
          const { data: { data } } = r;
          if (data) {
            data.forEach((item) => {
              this.staffMap[item.id] = {
                id: item.id,
                name: `${item.last_name} ${item.first_name}`,
                photo_url: item.photo_url,
                position: item.position,
              };
            });
          }
        }).catch((e) => console.warn(e))
        .finally(() => { this.loading = false; });
    },
    showAdd() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.checkpoint-template .checkpoint-template__name {
  color: #0B1218;
  max-height: 4.2em;
  line-height: 1.4em;
}

tbody tr:nth-of-type(even) {
  background-color: #F5F6F6;
}
.theme--light.v-data-table tbody tr td{
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table tbody tr td:last-child{
  border-right: none;
}
.theme--light.v-data-table tbody tr td:nth-last-of-type(2){
  border-right: none;
}
.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row){
  border-bottom: none;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border: none;
}
</style>
